// TO MAKE THE MENU DISAPPEAR IN MOBILE APPS

const toggle = (hamburgerTab, navElement, greenEdge) => {
    hamburgerTab.classList.toggle("active");
    navElement.classList.toggle("active");
    greenEdge.classList.toggle("active");
};

const setListeners = () => {
    const hamburgerTab = document.querySelector(".hamburger-tab");
    const navElement = document.querySelector(".site-navigation");
    const greenEdge = document.querySelector(".green-edge");
    const menuElement = document.querySelector(".main-menu");

    hamburgerTab && hamburgerTab.addEventListener("click", () => toggle(hamburgerTab, navElement, greenEdge));
    greenEdge && greenEdge.addEventListener("click", () => toggle(hamburgerTab, navElement, greenEdge));
    menuElement && menuElement.addEventListener("click", event => event.stopPropagation());
}

const link = document.createElement("link");
link.rel = "canonical";
link.href = window.location.href;
document.head.appendChild(link);

const menuList = [
    {
        item: "Nudura",
        url: "",
        submenu: [
            {
                item: "Overview",
                url: "whyNudura.html"
            },
            {
                item: "Products",
                url: "nuduraProducts.html"
            },
            {
                item: "ICF",
                url: "whatAreIcfs.html"
            },
            {
                item: "Cost Comparison",
                url: "plywoodVsIcf.html"
            },
            {
                item: "FAQs",
                url: "faq.html"
            },
            {
                item: "Building Code",
                url: "buildingCode.html"
            },
        ]
    },
    {
        item: "Installation",
        url: "siteGuide.html",
        submenu: []
    },
    {
        item: "Technical",
        url: "",
        submenu: [
            {
                item: "Blocks",
                url: "technicalDetailsOverview.html"
            },
            {
                item: "Bucks",
                url: "technicalDetailsBucks.html"
            },
            {
                item: "Corners, Ts",
                url: "technicalDetailsCorners.html"
            },
            {
                item: "Envelope",
                url: "technicalDetailsEnvelope.html"
            },
            {
                item: "Finishes",
                url: "technicalDetailsFinishes.html"
            },
            {
                item: "Floors, Slabs",
                url: "technicalDetailsFloors.html"
            },
            {
                item: "Footings",
                url: "technicalDetailsFootings.html"
            },
            {
                item: "Openings",
                url: "technicalDetailsOpenings.html"
            },
            {
                item: "Other",
                url: "technicalDetailsOther.html"
            }
        ]
    },
    {
        item: "Bracing",
        url: "",
        submenu: [
            {
                item: "Installation",
                url: "bracingInstall.html"
            },
            {
                item: "Rental Policy",
                url: "rentalPolicy.html"
            }
        ]
    },
    {
        item: "Monopour",
        url: "",
        submenu: [
            {
                item: "Overview",
                url: "monopourOverview.html"
            },
            {
                item: "Cost Compare",
                url: "mpStakedCostAnalysis.html"
            },
            {
                item: "MP Staked",
                url: "mpStakedOverview.html"
            },
            {
                item: "MP Side Supports",
                url: "mpSsOverview.html"
            },
            {
                item: "Helix",
                url: "mpHelix.html"
            }
        ]
    },
    {
        item: "Projects",
        url: "",
        submenu: [
            {
                item: "Project Listing",
                url: "projects.html"
            },
            {
                item: "Alair Homes",
                url: "alairHomes.html"
            },
            {
                item: "Kingdom Builders",
                url: "kingdom.html"
            },
            {
                item: "VictorEric Homes",
                url: "victorEric.html"
            },
            {
                item: "Upward Project",
                url: "upward.html"
            },
            {
                item: "JDL Homes",
                url: "jdlHomes.html"
            },
            {
                item: "Poetry Project",
                url: "shakespeare.html"
            },
            {
                item: "Basalt Project",
                url: "basalt.html"
            },
            {
                item: "KLS Project",
                url: "klsCoquitlam.html"
            },
            {
                item: "Plywood vs ICF",
                url: "purcellResidence.html"
            },
            {
                item: "Red Sky Project",
                url: "redSky.html"
            },
            {
                item: "12 Hour Install",
                url: "chilliwackDemo.html"
            },
            {
                item: "Ringmaster",
                url: "ringMaster.html"
            },
            {
                item: "MP Prefab",
                url: "usarSep2017.html"
            },
            {
                item: "MP Heritage",
                url: "ecoHeritage.html"
            },
            {
                item: "MP Townhouses",
                url: "valkyriesFoundation.html"
            },
            {
                item: "MP Naikoon",
                url: "naikoonCLM.html"
            }
        ]
    },
    {
        item: "Training",
        url: "nextTraining.html",
        submenu: []
    },
    {
        item: "Contact Us",
        url: "",
        submenu: [
            {
                item: "Contact",
                url: "contact.html"
            },
            {
                item: "About Us",
                url: "aboutUs.html"
            },
            {
                item: "Careers",
                url: "careers.html"
            },
            {
                item: "Trade Shows",
                url: "tradeShows.html"
            }
        ]
    },
    {
        item: "Site Map",
        url: "siteMap.html",
        submenu: []
    },
    {
        item: "Get Estimate",
        url: "getEstimate.html",
        submenu: []
    },
    {
        item: "(604)229-9829",
        url: "tel:1-604-229-9829",
        submenu: []
    },
]

const transformArrow = (item, isHidden) => {
    item.style.setProperty("--value", isHidden ? "0" : "scaleY(-1) translateY(-60%)");
}

const toggleMenu = (element) => {
    const list = element.querySelector("ul");
    const item = element.querySelector('.parent_menu_item');

    const menu = document.querySelectorAll(".menu_item");
    menu.forEach(item => {
        const child = item.querySelector("ul");
        const div = item.querySelector("div");
        if (child) child.hidden = true;

        if (div) div.style.removeProperty("--value");
    });

    list.hidden = !list.hidden;
    transformArrow(item, list.hidden)
}

const createMenuList = (menu, isHiding, isParent) => {
    const ul = document.createElement("ul");
    ul.hidden = isHiding;
    ul.id = isParent ? "parent" : "child";

    menu.forEach(element => {
        const li = document.createElement("li");
        li.classList.add("menu_item");

        if (element.url) {
            const a = document.createElement("a");
            a.href = element.url;
            a.textContent = element.item.toUpperCase();

           if (location.pathname.slice(1) && element.url.includes(location.pathname.slice(1))) {
                ul.hidden = false;
                a.style.textDecoration = "underline";
                a.style.color = "#006635";
           }

           li.appendChild(a);
        } else {
            const div = document.createElement("div");
            div.textContent = element.item.toUpperCase();
            div.classList.add("parent_menu_item");

            li.appendChild(div);

            if (element.submenu && element.submenu.length) {
                div.onclick =  () => toggleMenu(li);
                li.appendChild(createMenuList(element.submenu, true, false));
            }

        }

        ul.appendChild(li);
    })

    return ul;
}

const getOpenedMenu = () => {
    const menu = document.querySelectorAll(".menu_item");
    menu.forEach(item => {
        const ul = item.querySelector("ul")
        if (ul && !ul.hidden) {
            transformArrow(item.querySelector(".parent_menu_item"), ul.hidden);
        }
    })
}

const xhr = new XMLHttpRequest();
xhr.open('GET', 'header_1.html', true);
xhr.onreadystatechange = function() {
    if (this.readyState !== 4) return;
    if (this.status !== 200) return;

    const menuWrapper = document.querySelector("#menuWrapper")
    if (menuWrapper) menuWrapper.innerHTML = this.responseText;

    setListeners();

    const menu = document.querySelector(".main-menu");
    menu?.appendChild(createMenuList(menuList, false, true));
    getOpenedMenu();

    const copy = document.querySelector(".copyright");
    if (copy) copy.innerHTML = `${new Date().getFullYear()} &copy; Vancouver ICF`;
};
xhr.send();

const qouteBlock = () => {
    return `<div class="quote-block">
    <div class="quote-svg"></div>
    <div class="quote-actions">
        <div class="quote-text">READY FOR A QUOTE?</div>
        <div class="quote-buttons-block">
            <a href="/getEstimate.html"><div class="quote-button">Get a Quote</div></a>
            <a href="/projects.html"><div class="quote-button">Visit Live Jobsite</div></a>
        </div>
    </div>
    </div>
    `
}

const trainingBlock = () => {
    return `<div class="training-banner"><div class="chalkboard-svg"></div><div>Nudura Training - October 25th, 2024. <a href="./nextTraining" class="training-details">Click for details.</a></div></div>`
}

window.onload = () => {
    const block = document.querySelector(".imageMain");
    if (block && !(window.location.pathname === "/")) {
        block.outerHTML += qouteBlock() 
    }

    // if (!['/', '/nextTraining'].includes(window.location.pathname)) {
    //     const menuWrapper = document.querySelector("#menuWrapper");
    //     const div = document.createElement("div");
    //     div.innerHTML = trainingBlock();
    //     menuWrapper?.after(div);
    // }


}